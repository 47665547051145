import { NuxtApollo } from '#apollo'
import { decodeJwt } from 'jose'

export default defineStore('login', () => {
  const token = ref(useCookie(NuxtApollo.clients.default.tokenName).value)
  const user = computed(() =>
    token.value
      ? decodeJwt(token.value) as Record<
        'uid' | 'openid' | 'name' | 'face',
        string
      >
      : undefined)

  const { onLogin: _onLogin, onLogout: _onLogout } = useApollo()

  async function onLogin(_token: string) {
    token.value = _token
    await _onLogin(_token)
  }

  async function onLogout() {
    token.value = undefined
    await _onLogout()
  }

  return { token, user, onLogin, onLogout }
})
