import * as Sentry from '@sentry/vue'
import { browserTracingIntegration } from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const { public: { sentry } } = useRuntimeConfig()

  if (import.meta.dev || !sentry.dsn)
    return

  Sentry.init({
    app: nuxtApp.vueApp,

    dsn: sentry.dsn,

    integrations: [browserTracingIntegration({ router: useRouter() })],

    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,

    beforeSend(event) {
      event.user ||= {}
      event.user.cookie = import.meta.client
        ? document.cookie
        : useRequestHeader('cookie')

      return event
    },
  })

  nuxtApp.provide('sentry', {
    captureException: Sentry.captureException,
    captureMessage: Sentry.captureMessage,
    withScope: Sentry.withScope,
  })
})
